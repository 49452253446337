.influencer-collaboration {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.influencer-collaboration__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.influencer-collaboration__text-section {
  max-width: 600px;
  margin-bottom: 20px;
}

.influencer-collaboration__header {
  font-size: 1.5em;
  font-weight: bold;
  color: #3e7ca8;
}

.influencer-collaboration__description {
  font-size: 1em;
  color: #000;
  line-height: 1.6;
  width: 100%;
}

.influencer-collaboration__img {
  max-width: 100%;
  margin: 20px 0;
}

.influencer-collaboration__img--responsive {
  width: 100%;
  height: auto;
  max-height: 600px;
}

.influencer-collaboration__section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  margin-top: 20px;
}

.influencer-collaboration__section-header {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #3e7ca8;
}

.influencer-collaboration__steps,
.influencer-collaboration__features,
.influencer-collaboration__benefits,
.influencer-collaboration__stories {
  list-style-type: none;
  padding: 0;
  font-size: 1em;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.influencer-collaboration__steps li,
.influencer-collaboration__features li,
.influencer-collaboration__benefits li,
.influencer-collaboration__stories li {
  padding: 10px 0;
}

.influencer-collaboration__cta {
  text-align: center;
  padding: 40px 20px;
  background-color: #3e7ca8;
  color: white;
}

.influencer-collaboration__cta h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: white;
}

.influencer-collaboration__signup-button {
  background-color: #fff;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.influencer-collaboration__signup-button:hover {
  background-color: #f1f1f1;
}

.influencer-collaboration__section {
  text-align: center;
  padding: 2rem;
  /* background: #f7faff; */
}

.influencer-collaboration__section-header {
  font-size: 2.2rem;
  color: #3e7ca8;
  margin-bottom: 1.5rem;
}

.influencer-collaboration__testimonials {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.influencer-collaboration__testimonial-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 280px;
  padding: 1rem;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.influencer-collaboration__testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.influencer-collaboration__image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
}

.influencer-collaboration__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.influencer-collaboration__name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.influencer-collaboration__quote {
  font-size: 1rem;
  color: #555;
  font-style: italic;
}

.influencer-collaboration__signup-button {
  background-color: #fff;
  border: none;
  padding: 5px 24px;
  border-radius: 8px;
  font-size: 16px;
  /* font-weight: bold; */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.influencer-collaboration__signup-button a {
  color: #fff;
  text-decoration: none;
}

.influencer-collaboration__signup-button:hover {
  background-color: #fff; /* Slightly darker shade for hover */
}

.influencer-collaboration__signup-button:active {
  transform: scale(0.98); /* Slightly shrink on click for feedback */
}

@media (min-width: 768px) {
  .influencer-collaboration__hero {
    flex-direction: row;
    text-align: left;
  }

  .influencer-collaboration__text-section {
    margin-right: 20px;
  }

  .influencer-collaboration__description {
    width: 100%;
  }
}
