.brand-influencer {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.brand-influencer__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.brand-influencer__text-section {
  max-width: 600px;
  margin-bottom: 20px;
}

.brand-influencer__header {
  font-size: 1.5em;
  font-weight: bold;
  color: #3e7ca8;
}

.brand-influencer__description {
  font-size: 1em;
  color: #000;
  line-height: 1.6;
  width: 100%;
}

.brand-influencer__img {
  max-width: 100%;
  margin: 20px 0;
}

.brand-influencer__img img {
  border-radius: 10px;
}

.brand-influencer__img--responsive {
  width: 100%;
  height: auto;
  max-height: 600px;
}

.brand-influencer__section {
  padding: 40px 20px;
  /* background-color: #f9f9f9; */
  margin-top: 20px;
}

.brand-influencer__section-header {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #3e7ca8;
}

.brand-influencer__steps,
.brand-influencer__benefits,
.brand-influencer__stories {
  list-style-type: none;
  padding: 0;
  font-size: 1em;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.brand-influencer__steps li,
.brand-influencer__benefits li,
.brand-influencer__stories li {
  padding: 10px 0;
}

.brand-influencer__cta {
  text-align: center;
  padding: 40px 20px;
  background-color: #3e7ca8;
  color: white;
}

.brand-influencer__cta h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: white;
}

.brand-influencer__signup-button {
  background-color: #fff;
  padding: 5px 20px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.brand-influencer__signup-button:hover {
  background-color: #f1f1f1;
}

.brand-influencer__section-header {
  font-size: 2rem;
  font-weight: 700;
  color: #3e7ca8;
  margin-bottom: 1.5rem;
}

.brand-influencer__stories {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.brand-influencer__card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.brand-influencer__card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.brand-influencer__icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: #ff6f61;
}

@media (min-width: 768px) {
  .brand-influencer__hero {
    flex-direction: row;
    text-align: left;
  }

  .brand-influencer__text-section {
    margin-right: 20px;
  }

  .brand-influencer__description {
    width: 100%;
  }
}
