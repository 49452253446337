/* Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f7f6;
}

.cube-container {
  perspective: 1000px;
}

.cube {
  width: 50px;
  height: 50px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(45deg) rotateY(45deg);
  animation: rotateCube 1.5s infinite ease-in-out;
}

.face {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #3e7ca8;
  border: 2px solid #fff;
  opacity: 0.8;
}

.face1 {
  transform: rotateY(0deg) translateZ(25px);
}
.face2 {
  transform: rotateY(90deg) translateZ(25px);
}
.face3 {
  transform: rotateY(180deg) translateZ(25px);
}
.face4 {
  transform: rotateY(-90deg) translateZ(25px);
}
.face5 {
  transform: rotateX(90deg) translateZ(25px);
}
.face6 {
  transform: rotateX(-90deg) translateZ(25px);
}

@keyframes rotateCube {
  0% {
    transform: rotateX(45deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(405deg) rotateY(45deg);
  }
  100% {
    transform: rotateX(405deg) rotateY(405deg);
  }
}

.loading-container p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}
