.footer {
  background-color: #2c2c2c;
  color: #ffffff;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-column {
  max-width: 250px;
  flex: 1;
}

.footer-column h3 {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.footer-column p,
.footer-column ul {
  margin-bottom: 10px;
  color: #ccc;
  font-size: 13px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  color: #ccc;
}

.footer-column ul li a {
  color: #ccc;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #444;
  padding-top: 20px;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
}

@media (max-width: 900px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-column {
    max-width: 100%;
  }
}
