header {
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
  margin-bottom: 100px;
}

.pc__nav,
.mobile__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 9000;
  background-color: #2c2c2c;
  box-shadow: 0 2px 4px rgba(238, 231, 231, 0.1);
  padding: 20px;
  top: 0;
  left: 0;
}

.mobile__nav {
  display: none;
}

.mobile-auth-buttons {
  display: none;
}

.logo {
  width: 60px;
  height: 60px;
  /* border-radius: 50%; */
  /* overflow: hidden; */
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-buttons .sign-up,
.auth-buttons .get-started {
  background-color: #3e7ca8;
  color: #fff;
  border: none;
  padding: 15px 35px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
}

.auth-buttons .sign-up {
  background-color: #fff;
  color: #3e7ca8;
  border: 1px solid #3e7ca8;
}

.auth-buttons .get-started:hover,
.auth-buttons .sign-up:hover {
  opacity: 0.8;
}

.sign-up a {
  color: #3e7ca8;
  text-decoration: none;
}

.get-started a {
  color: #fff;
  text-decoration: none;
}

.nav-link--submenu {
  position: relative;
}

.submenu {
  display: none;
  position: absolute;
  top: 20;
  left: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  list-style: none;
  width: 300px;
}

.nav-link--submenu:hover .submenu,
.nav-link--submenu .submenu-open {
  display: block;
}

.submenu li a {
  color: black;
  text-decoration: none;
  padding: 5px 10px;
  display: block;
}
.submenu li a:hover {
  color: #1970cd;
}

@media (max-width: 900px) {
  .pc__nav {
    display: none;
  }

  .mobile-menu .submenu {
    position: static;
    background-color: #f0f0f0;
    box-shadow: none;
    width: 250px;
  }

  .mobile-menu .submenu li a {
    color: #333;
    margin-bottom: -22px;
  }

  .sign-up a {
    color: #fff;
    text-decoration: none;
  }

  .mobile {
    display: block;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .hamburger-menu {
    cursor: pointer;
  }

  .mobile-menu {
    display: block;
    background-color: #ffffff;
    height: 100vh;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    position: fixed;
    top: 2rem;
    z-index: 1000;
    width: 90%;
    z-index: 99999;
  }

  .mobile-menu.active {
    display: block;
  }

  .mobile-auth-buttons {
    display: block;
    background-color: #fffefe;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    position: fixed;
    top: 1rem;
    width: 100%;
    z-index: 99999;
  }

  .mobile-auth-buttons button {
    padding: 10px 20px;
    /* width: 40%; */
    border: none;
    margin: 0 auto;
    border-radius: 30px;
    color: #fff;
    background-color: #3e7ca8;
  }

  .mobile-links {
    list-style: none;
    padding: 0;
    /* text-align: center; */
  }

  .mobile-links li {
    margin-bottom: 30px;
  }

  .mobile-links li a {
    text-decoration: none;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
  }
}
