* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

body {
  color: #333;
  /* font-weight: 200; */
  font-size: 15px;
  max-width: 1600px;
  margin: 0 auto;
}

#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #3e7ca8;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
  right: 10px;
}

#scrollToTopBtn:hover {
  background-color: #555;
}
